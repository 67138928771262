const Cookie = require('./../lib/cookies');
const Event = require('./../lib/events');

/**
 * Cookie Notice
 *
 * This module is a simple implementation of a CookieWall
 * It gives the user only a notice about the use of cookies.
 *
 * By clicking on the agreement on the buttons "Yes" and "no",
 * the notice will be removed and won't be shown within the life
 * span of the cookie.
 *
 * Basic HTML usage:
 * <div data-cookie-notice>
 *      <a data-set-cookie="no" href="#">No</a>
 *      <a data-set-cookie="yes" href="#">Yes</a>
 * </div>
 *
 * Future implementation will contain the actual setting of the
 * TrackingCookies by either Google Analytics, GTM or Hotjar.
 */

class CookieNotice {

    constructor() {

        this.cookieNotice = document.querySelector('[data-cookie-notice]');
        this.cookieName = 'tracking_allowed';

        /**
         * 0: disallow tracking (default)
         * 1: allow
         * @type {string}
         */
        this.status = '0';

        if (this.cookieNotice) {

            this.expire = this.cookieNotice.dataset.cookieExpire;

            this.handleUserInteraction();

            //  Check for Browser option of user
            if (this.checkDoNotTRackOption()) {

                //  Check if user (dis)agreed within Cookie life span, if not: show CookieWall
                if (Cookie.read(this.cookieName) === null) {

                    this.showCookieWall();

                } else {

                    if (Cookie.read(this.cookieName) === '1') {

                        //  On pageload the user will be tracked if he allowed it by CookieWall
                        Event.trigger(App.Globals.window, 'site:allow-tracking');

                    }

                }

            }

        }

    }

    /**
     * User chooses to either allow or disallow tracking
     */
    handleUserInteraction() {

        Event.on('click', '[data-set-cookie]', (event) => {
            event.preventDefault();

            if (event.currentTarget.dataset.setCookie === 'yes') {

                //  Custom event trigger
                Event.trigger(App.Globals.window, 'site:allow-tracking');

                this.status = '1';

            }

            Cookie.create(this.cookieName, this.status, this.expire);
            this.hideCookieWall();
        });

    }

    /**
     * Check if the "Do Not Track" option is on in the browser
     * Exclude IE10 & IE11 as the option is "true" by default and
     * tracking will never happen if the user doesn't change it manually
     * Other browsers have the option on "false" as user is required to
     * "opt-out" manually.
     *
     * @returns {*|boolean}
     */
    checkDoNotTRackOption() {

        //  Respect do not track for all browsers. Except for IE 10 and 11 where we ignore it
        let doNotTrackIE = (navigator.appVersion.indexOf('MSIE 10') !== -1) || (navigator.userAgent.indexOf('Trident') !== -1 && navigator.userAgent.indexOf('rv:11') !== -1);

        let doNotTrack = (navigator.doNotTrack || navigator.msDoNotTrack || window.doNotTrack);
        doNotTrack = !doNotTrack || doNotTrack == 'unspecified' || doNotTrack == '0';

        return (doNotTrack || doNotTrackIE);

    }

    /**
     * Adds a class name to the <body>, css will show the HTML of the
     * cookie-notice box.
     */
    showCookieWall() {

        App.Globals.body.classList.add('cookie-notice-open');

    }

    /**
     * Removes a class name from the <body>, css will hide the HTML of the
     * cookie-notice box.
     */
    hideCookieWall() {

        App.Globals.body.classList.remove('cookie-notice-open');

    }

}

module.exports = CookieNotice;