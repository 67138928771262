'use strict';

class Header {

    constructor() {

        let fixedBodyClass = 'fixed-header';
        let headerOffset = 24;
        let openSubmenuBodyClass = 'submenu-open';

        App.Globals.$window.on('load scroll touchmove', function () {

            let windowTop = $(this).scrollTop();

            if (windowTop >= headerOffset) {
                App.Globals.$body.addClass(fixedBodyClass);
            } else {
                App.Globals.$body.removeClass(fixedBodyClass);
            }
        });

        App.Globals.$window.on('load resize', function () {
            if(App.Globals.$window.width() > 1023) {
                $('.header .has-children').hover(
                    function() {
                        App.Globals.$body.addClass(openSubmenuBodyClass);
                    }, function() {
                        App.Globals.$body.removeClass(openSubmenuBodyClass);
                    }
                );
            } else {
                $('.has-children .icon-chevron-down').on('click', function() {
                    $(this).prev().toggle().toggleClass('dropdown-open');
                    $(this).toggleClass('active');
                });
            }
        });

        let SubSubMenu = $('.subsubmenu');
        let SubSubMenuLength = SubSubMenu.find('.subsub-inner-wrapper').children().length;

        if (App.Globals.$window.width() > 1023 && SubSubMenuLength > 8) {
            SubSubMenu.addClass('columns');
        }

    }

}

module.exports = Header;