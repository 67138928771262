'use strict';

const $ = require('jquery');

class Form {

    constructor() {

        // Documentation: https://jqueryvalidation.org/validate/
        require('./../../../../build/node_modules/@components/jquery-validation/dist/jquery.validate.min.js');
        // require('./../../../../build/node_modules/@components/jquery-validation/dist/localization/messages_nl.min.js');

        let $form = $('.rk_form');

        if($form.length > 0) {
            $form.each(function() {
                $(this).validate({
                    errorPlacement: function(error, element) {
                        error.appendTo(element.closest('fieldset'));
                    },
                    highlight: function(element) {
                        $(element).closest('fieldset').addClass('error').removeClass('success');
                    },
                    unhighlight: function(element) {
                        $(element).closest('fieldset').removeClass('error').addClass('success');
                    },
                    errorElement: 'span',
                    errorClass: 'error-message'
                });
            });
        }

        // Documentation: https://jqueryui.com/datepicker
        require('./../../../../build/node_modules/@components/jquery-ui/ui/widgets/datepicker.js');

        $('.datepicker-wrapper input').datepicker({ dateFormat: 'd MM yy' }).val();

        $('.custom-file-upload').change(function() {
            let stringReplace = this.value.match(/([^\/\\]+)$/);
            let fileName = stringReplace[1];
            $(this)
                .closest('.custom-file-upload-wrapper')
                .find('.file-name').html(fileName);

        });

        if ($('.ce-cycletourscontentelements_two_way, .ce-cycletourscontentelements_travel_dates').length > 0) {
            $('.radio input').on('change', function () {
                let $radio = $('.radio');
                $radio.removeClass('checked');

                $('.accordion').hide();
                $('.' + $radio.find('input:checked').val()).show();
                $(this).parent('.radio').addClass('checked');
            });
        }

    }

}

module.exports = Form;
