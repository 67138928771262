const Cookie = (function () {

    const self = {};

    self.create = function (name, value, days) {
        let expires = '';

        if (days) {
            let date = new Date();
            date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
            expires = '; expires=' + date.toGMTString();
        }

        document.cookie = name+ '=' + value + expires + '; path=/';
    };

    self.read = function (name) {
        let nameEQ = name + '=';
        let ca = document.cookie.split(';');

        for(let i = 0; i < ca.length; i++) {

            let c = ca[i];

            while (c.charAt(0) == ' ') {
                c = c.substring(1, c.length);
            }

            if (c.indexOf(nameEQ) === 0) {
                return c.substring(nameEQ.length, c.length);
            }
        }

        return null;
    };

    self.remove = function (name) {
        cookie.create(name, '', -1);
    };

    return self;

}());

module.exports = Cookie;
