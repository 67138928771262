const Event = {

    /**
     * Function to trigger a (custom) event
     * @param target
     * @param name
     * @param detail
     */
    trigger: (target, name, detail) => target.dispatchEvent(
        new CustomEvent(name, {
            bubbles: true,
            cancelable: true,
            detail: detail
        })
    ),

    /**
     * Listener for multiple events
     * @param event
     * @param selector
     * @param fn
     */
    on: (event, selector, fn) => {

        //  Split on space
        let events = event.split(' ');

        //  Check if selector is window pass the window object, otherwise it will be the document providing the selector
        if (selector === window) {

            if (events.length > 0) {
                for (let event of events)  {
                    window.addEventListener(event, fn, false);
                }
            }

        } else {

            let selectors = document.querySelectorAll(selector);

            if (events.length > 0) {

                for (let event of events) {

                    if (selectors.length > 0) {
                        for( let element of selectors ) {
                            element.addEventListener(event, fn, false);
                        }
                    }
                }

            }

        }

    },

};

module.exports = Event;
