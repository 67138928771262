/**
 *
 * Scroller represents an internal link scroller.
 * The href="#target" (anchor) or the data-scroll="#target" (any other element) is the target to scroll to. Make sure an ID is unique...
 * data-scroll-offset="#header" is an optional setting to subtract the height of given element. Only one element allowed (either ID or CLASS)
 *
 * Example:
 * <a href="#main" class="scroller" data-scroll data-scroll-offset="#header">Text</a>
 *
 */

'use strict';

const $ = require('jquery');
const Event = require('./../lib/events');

class Scroller {

    constructor() {

        Event.on('click', '[data-scroll]', (event) => {

            event.preventDefault();

            let clickedElement = event.currentTarget;
            let scroll = true;
            let target = false;
            let offset = 0;

            if (clickedElement.getAttribute('data-scroll')) {

                target = clickedElement.getAttribute('data-scroll');

            } else if (clickedElement.href) {

                target = clickedElement.href;

            } else {

                scroll = false;

            }

            if (clickedElement.getAttribute('data-scroll-offset')) {

                let offsetElements = document.querySelectorAll(clickedElement.getAttribute('data-scroll-offset'));

                if (offsetElements.length > 0) {

                    offsetElements.forEach((offsetElement) => {
                        offset += offsetElement.clientHeight;
                    });

                }

            }

            if (scroll && target) {

                this.scrollIt(document.querySelector(target), offset);

            }

        });

    }

    scrollIt (destination, offset = 0, duration = 200, callback) {

        const start = window.pageYOffset;
        const startTime = 'now' in window.performance ? performance.now() : new Date().getTime();

        const documentHeight = Math.max(document.body.scrollHeight, document.body.offsetHeight, document.documentElement.clientHeight, document.documentElement.scrollHeight, document.documentElement.offsetHeight);
        const windowHeight = window.innerHeight || document.documentElement.clientHeight || document.getElementsByTagName('body')[0].clientHeight;
        const destinationOffset = typeof destination === 'number' ? destination : destination.offsetTop;
        const destinationOffsetToScroll = Math.round(documentHeight - destinationOffset < windowHeight ? documentHeight - windowHeight : destinationOffset) - offset;

        if ('requestAnimationFrame' in window === false) {
            window.scroll(0, destinationOffsetToScroll);
            if (callback) {
                callback();
            }
            return;
        }

        function scroll() {
            const now = 'now' in window.performance ? performance.now() : new Date().getTime();
            const time = Math.min(1, ((now - startTime) / duration));

            window.scroll(0, Math.ceil((time * (destinationOffsetToScroll - start)) + start));

            if (window.pageYOffset === destinationOffsetToScroll) {
                if (callback) {
                    callback();
                }
                return;
            }

            requestAnimationFrame(scroll);
        }

        scroll();
    }

}

module.exports = Scroller;
