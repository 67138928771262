/**
 * Add components by event and in your desired order
 * @type {[*]}
 */
let components = [
    ['ready', [
        require('./../components/page-transitions')
    ]],
    ['ready pjax:end', [
        // Initial load
        require('./../components/foundation'),

        // Components
        require('./../components/birds-eye'),
        require('./../components/cookie-notice'),
        require('./../components/filter'),
        require('./../components/form'),
        require('./../components/header'),
        require('./../components/maps'),
        require('./../components/play-media'),
        require('./../components/scroller'),
        require('./../components/slider'),
        require('./../components/trigger'),
        require('./../components/waypoints'),
    ]],
];

module.exports = components;
