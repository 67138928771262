'use strict';

const $ = require('jquery');
const Event = require('./../lib/events');

class Slider {

    constructor() {

        this.mediaSlider();
        this.uspsSlider();

    }

    mediaSlider() {

        let $mediaSwiper = $('.ce-rkmediaslider_media_slider');

        if ($mediaSwiper.length > 0) {

            // Documentation: https://idangero.us/swiper/api
            const Swiper = require('./../../../../build/node_modules/@components/swiper/dist/js/swiper.min.js');

            $mediaSwiper.each(function () {

                new Swiper($(this).find('.swiper-container')[0], {
                    navigation: {
                        prevEl: $(this).find('.swiper-button-prev')[0],
                        nextEl: $(this).find('.swiper-button-next')[0]
                    },
                    pagination: {
                        clickable: false,
                        el: $(this).find('.swiper-pagination')[0],
                        renderCustom: function (swiper, current, total) {
                            function numberAppend(d) { return (d < 10) ? '0' + d.toString() : d.toString(); }
                            return '<span class="swiper-fraction-current">' + numberAppend(current) + '</span>' + ' <span class="swiper-fraction-divider">/</span> ' + '<span class="swiper-fraction-total">' + numberAppend(total) + '</span>';
                        },
                        type: 'custom'
                    },
                    speed: 600,
                    watchOverflow: true
                });

            });

        }

    }

    uspsSlider() {

        let $uspsSwiper = $('.top-navigation');

        if ($uspsSwiper.length > 0) {

            // Documentation: https://idangero.us/swiper/api
            const Swiper = require('./../../../../build/node_modules/@components/swiper/dist/js/swiper.min.js');

            $uspsSwiper.each(function () {

                new Swiper($(this).find('.swiper-container')[0], {
                    breakpoints: {
                        767: {
                            direction: 'vertical',
                            loop: true,
                            slidesPerView: 1
                        }
                    },
                    autoplay: {
                        delay: 3000
                    },
                    slidesPerView: 'auto',
                    speed: 600,
                    watchOverflow: true
                });

            });

        }

    }

}

module.exports = Slider;
