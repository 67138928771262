'use strict';

class Foundation {

    constructor() {

        require('./../../../../build/node_modules/@components/foundation-sites/dist/js/foundation.js');

        const svg4everybody = require('./../../../../build/node_modules/@components/svg4everybody/dist/svg4everybody.min.js');
        const inlineSVG = require('./../../../../build/node_modules/@components/inline-svg/src/inlineSVG.js');

        App.Globals.$document.foundation();

        //  Internet Explorer SVG
        svg4everybody();

        //  SVG Inline
        inlineSVG.init({
            svgSelector: 'img.svg',
            initClass: 'js-inlinesvg'
        });

    }

}

module.exports = Foundation;
