'use strict';

const Event = require('./../lib/events');

class Trigger {

    constructor() {

        this.openClassName = '-open';

        Event.on('click', '[data-trigger]', (event) => {
            event.preventDefault();

            this.toggle(event.target.dataset.trigger);
        });

        Event.on('click', '[data-trigger-close]', (event) => {
            event.preventDefault();

            this.close(event.target.dataset.triggerClose);
        });

    }

    /**
     * Toggle class on document.body
     * @param target
     */
    toggle(target) {

        if (target) {

            App.Globals.body.classList.toggle(target + this.openClassName);

            //  For search we need a focus on the input field
            if (target === 'search') {

                setTimeout(function () {
                    document.querySelector('.header-search input').focus();
                }, 700);

            }

        }

    }

    /**
     * Remove class on document.body
     * @param target
     */
    close(target) {

        if (target) {

            let targets = target.split(',');

            if (targets.length > 0) {

                for (let i = 0; i < targets.length; i++) {

                    App.Globals.body.classList.remove(targets[i] + this.openClassName);

                }

            }

        }

    }

}

module.exports = Trigger;
