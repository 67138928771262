'use strict';

class PlayMedia {

    constructor() {

        PlayMedia.prototype.playClass = 'is-playing';
        PlayMedia.prototype.muteClass = 'is-muted';
        PlayMedia.prototype.observers = [];

        let videoControl = document.querySelectorAll('[data-video-control]');

        if (videoControl.length > 0) {

            for (let control of videoControl) {
                control.addEventListener('click', (event) => {
                    event.preventDefault();

                    let target = event.currentTarget;
                    let action = target.dataset.videoControl; // play | pause | mute
                    let mediaHolder = target.closest('.media-holder'); // TODO: IE compatible?
                    let video = mediaHolder.querySelector('video');

                    //  Play current clicked video
                    PlayMedia.prototype.controlVideo(video, action);

                    //  Check if Element is Observance mode, unobserve video on User Interaction
                    let currentVideo = mediaHolder.querySelector('.responsive-embed');

                    if (currentVideo && action !== 'mute') {

                        let currentVideoId = currentVideo.id;
                        let currentVideoKey = currentVideoId.replace('video-', '');

                        if (PlayMedia.prototype.observers[currentVideoKey]) {

                            PlayMedia.prototype.observers[currentVideoKey].unobserve(document.querySelector(`#${currentVideoId}`));
                            delete PlayMedia.prototype.observers[currentVideoKey];

                        }

                    }

                });
            }

        }

        let videos = document.querySelectorAll('video');
        let playPromises = 0;


        if (videos.length > 0) {

            for (let video of videos) {

                let playPromise = video.play();

                if (playPromise !== undefined) {

                    playPromise
                        .then(_ => {

                            video.pause();

                            let mediaHolder = video.closest('.media-holder');
                            mediaHolder.classList.remove(PlayMedia.prototype.playClass, PlayMedia.prototype.muteClass);

                            playPromises++;

                            if (playPromises === videos.length) {
                                PlayMedia.prototype.observeVideos();
                            }
                        })
                        .catch(error => {
                            console.log(error, video);
                        });

                }

            }

        }

    }

    /**
     *
     * @param video
     * @param action
     */
    controlVideo(video, action) {

        if (video) {

            let mediaHolder = video.closest('.media-holder');

            switch (action) {
                case 'play':

                    //  Adds class to parent media holder
                    mediaHolder.classList.add(PlayMedia.prototype.playClass);

                    //  Starts the video
                    video.play();

                    break;
                case 'pause':

                    mediaHolder.classList.remove(PlayMedia.prototype.playClass, PlayMedia.prototype.muteClass);

                    video.pause();

                    break;
                case 'mute':

                    // Sets muted true or false
                    mediaHolder.classList.toggle(PlayMedia.prototype.muteClass);
                    video.muted = !video.muted;

                    break;
            }

        }

    }

    /**
     * Stops/pauses all video's
     */
    stopAllVideos() {

        let video = document.querySelectorAll('video');

        video.forEach((video) => {

            let parentMediaHolder = video.closest('.media-holder'); // TODO: IE compatible?

            //  Stops video
            video.pause();

            //  Remove classnames from media holder
            if (parentMediaHolder.length > 0) {
                parentMediaHolder.classList.remove(PlayMedia.prototype.playClass, PlayMedia.prototype.muteClass);
            }

        });

    }

    observeVideos() {

        //  Initiate Observer for each video element to automatically play/pause
        PlayMedia.prototype.initObserver(
            'video',
            '.responsive-embed',
            {
                root: null,
                rootMargin: '0px',
                threshold: [0.0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1.0]
            },
            PlayMedia.prototype.videoBlocks
        );

    }

    /**
     * Initiate IntersectionObserver
     * @param name
     * @param selector
     * @param options
     * @param callback
     */
    initObserver(name, selector, options, callback) {

        let elements = document.querySelectorAll(selector);

        if (elements.length > 0) {


            for (let i = 0; i < elements.length; i++) {

                let boxID = `${name}-${i}`;
                elements[i].id = boxID;

                PlayMedia.prototype.observers[i] = new IntersectionObserver(callback, options);
                PlayMedia.prototype.observers[i].observe(document.querySelector('#' + boxID));

            }

        }

    }

    /**
     * Callback for automatic playing/ pausing video's in viewport
     * @param entries
     */
    videoBlocks(entries) {

        entries.forEach((entry) => {
            let visiblePercentage = Math.floor(entry.intersectionRatio * 100);
            let videoEmbed = entry.target;
            let video = videoEmbed.querySelector('video');

            if (visiblePercentage > 50) {

                PlayMedia.prototype.controlVideo(video, 'play');

            } else {

                PlayMedia.prototype.controlVideo(video, 'pause');

            }

        });

    }

}

module.exports = PlayMedia;