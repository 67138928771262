'use strict';

const $ = require('jquery');

//  Location Manager (https://github.com/KidkArolis/location-bar)
let LocationBar = require('./../lib/location-bar');
let $filter = $('[data-ajax-area]');
let locationBar = new LocationBar();

class Filter {

    constructor() {

        //  Check if filter exists
        if ($filter.length > 0) {

            let $resultHolder = $filter.find('[data-results-holder]');

            let $filterAllResultsCount = $('[data-ajax-filter-all-results-count]');

            locationBar.start({
                pushState: true
            });
            
            //  Ajax the changed URL
            locationBar.onChange(function (path) {
                
                App.Globals.$body.addClass('page-transition');
                App.Globals.$document.trigger('site:filter:start', path);

                if (App.Globals.debug) {
                    console.log('path: /' + path);
                }

                $.ajax({
                    url: '/' + path,
                    dataType: 'html'
                }).done(function (data) {

                    App.Globals.$document.trigger('site:filter:end', path);
                    // this holds the entire HTML page
                    let $data = $(data);

                    let $listItems = $data.find('[data-results-holder]').html();
                    $resultHolder.html($listItems).foundation();

                    //  Total amount of results to show (mobile overlay button)
                    let $currentAllResultsCount = $data.find('[data-ajax-filter-all-results-count]').html();
                    $filterAllResultsCount.html($currentAllResultsCount);

                    App.Globals.$body.removeClass('page-transition');

                });

            });

            $filter.on('click', '.show-more-filters', function (ev) {
                ev.preventDefault();

                let $togglebutton = $(this);
                
                $togglebutton.prev().toggleClass('expanded');
                $togglebutton.toggleClass('toggled');
                $togglebutton.text(
                    $togglebutton.text() == $togglebutton.data('show-more') ? $togglebutton.data('show-less') : $togglebutton.data('show-more'));
            });

            $filter.on('change', 'input[type="checkbox"]', function () {
                // Handle front-end view of selected checkbox, after page reload fluid will add the class
                $(this).closest('.checkbox').toggleClass('selected');
                console.log($(this));

                //  Handle all field of the form to process the filters (taxonomies)
                $('[data-filter-form]').submit();
           });

            //  Handle form submit
            $filter.on('submit', '[data-filter-form]', function (ev) {
                ev.preventDefault();

                let $this = $(this);
                let hash = window.location.hash;
                let pathname = window.location.pathname;
                let suffix = '';

                // check if there are search query parameter.
                let searchQuery = '';
                let search = window.location.search;
                let queryparams = search.split('&');

                for (let i = 0; i < queryparams.length; i++) {

                    // if the tx_solr[q] param is available add it to end of the new filter url
                    // if the param has a ? remove it
                    if (queryparams[i].indexOf('tx_solr%5Bq%5D') > -1) {

                        searchQuery = queryparams[i].replace('?', '');

                    }
                }

                //  Strip paginator from filter.
                //  When you're on page 2 and use the filter you need a new search, not a filtered page 2
                let url = pathname.replace(/page\/[0-9]\//g, '');

                //  Serialized form data
                let data = $this.find(':input').filter(function (index, element) {
                    return $(element).val() != '';
                }).serialize();

                if (data) {
                    url = url + '?' + decodeURIComponent(data);

                    if (searchQuery) {
                        searchQuery = '&' + searchQuery;
                    }

                } else if (searchQuery) {
                    searchQuery = '?' + searchQuery;
                }

                if (hash) {
                    suffix = hash;
                }

                //  Update URL
                locationBar.update(url + searchQuery + suffix, { trigger: true });

            });

            //  Handle paginator
            $resultHolder.on('click', '.pagination a', function (ev) {
                ev.preventDefault();

                let url = $(this).attr('href');
                let suffix = '';
                let hash = window.location.hash;

                if (hash) {
                    suffix = hash;
                }

                //  Update URL
                locationBar.update(url + suffix, { trigger: true });

                App.Globals.$scrollAnimators.animate({
                    scrollTop: $('.facets-filter-main').offset().top - $('.header').height() - 32
                }, 1500);

            });

        }

    }

}

module.exports = Filter;