'use strict';

const $ = require('jquery');

class BirdsEye {

    constructor() {

        let bodyClass = 'fixed-birds-eye';
        let $fixedElement = $('.birds-eye-view header');
        let headerElementHeight = $('.header').outerHeight();
        
        if($fixedElement.length > 0) {

            let offsetTopFixedElement = $fixedElement.offset().top;

            App.Globals.$window.on('load scroll resize touchmove', function () {

                if (App.Globals.$window.width() >= 768) {
                    let windowTop = $(this).scrollTop();
        
                    if (windowTop >= (offsetTopFixedElement - headerElementHeight)) {
        
                        $fixedElement.css('top', 0);
                        App.Globals.$body.addClass(bodyClass);
        
                    } else {
        
                        App.Globals.$body.removeClass(bodyClass);
        
                    }
                }
    
            });
            
        }

    }

}

module.exports = BirdsEye;