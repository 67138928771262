(function ($) {

    const self = {};

    const methods = {
        init: function (options) {
            self.settings = $.extend(true, {
                cluster: {
                    show: false,
                    maxZoom: 4,
                    styles: false
                },
                infoWindow: {
                    show: false,
                    panOnClose: false
                },
                layer: {
                    show: false,
                    layers: []
                },
                map: {
                    draggable: false,
                    fullscreenControl: false,
                    mapTypeControl: false,
                    overviewMapControl: false,
                    panControl: false,
                    scaleControl: false,
                    scrollwheel: false,
                    streetViewControl: false,
                    styles: false,
                    zoom: 14,
                    zoomControl: true
                },
                markers: {
                    data: $('[data-marker]')
                }
            }, options);

            self.$mapObject = this;

            //  Check if element exists. If not abort ...
            if (!self.$mapObject) return;

            self.markers = [];
            self.bounds = new google.maps.LatLngBounds();

            if (self.settings.infoWindow.show) {
                self.infoWindow = new google.maps.InfoWindow();
            }

            //  Initiate Google Map
            //  The Google Maps self.settings are passed in one on one. There are some default self.settings to build a descent Map.
            self.map = new google.maps.Map(self.$mapObject.find('[data-google-map-holder]')[0], self.settings.map);

            //  Check if this object is a jQuery Object. If true, we will try and find the $('[data-marker]')
            if (self.settings.markers.data instanceof jQuery) {

                methods.mapHtmlMarkers(self.settings.markers.data);

            } else {

                //  This accepts a data object. Can be formed by an Ajax request or other source to your likings.
                if (self.settings.markers.data.length > 0) {

                    for (let index = 0; index < self.settings.markers.data.length; index++) {
                        methods.addMarker(self.settings.markers.data[index]);
                    }

                }

            }

            //  Add Cluster
            methods.addCluster();

            //  Add Layers
            methods.addLayers();

            //  Recalculate Map Bounds
            methods.setBounds();

            //  Fix zoom on 1 marker
            let listener = google.maps.event.addListener(self.map, 'idle', function () {

                if (self.map.getZoom() > 16) {
                    self.map.setZoom(16);
                }

                google.maps.event.removeListener(listener);

                self.$mapObject.trigger('map:loaded');

            });

        },
        /**
         * Initialize the Clusterer, It needs an extra library to work
         */
        addCluster: function () {

            //  Check if Cluster is requested
            if (self.settings.cluster.show) {

                //  Default Cluster Settings
                let clusterSettings = {
                    gridSize: 50
                };

                //  Set ClusterStyles
                if (self.settings.cluster.styles) {
                    clusterSettings.styles = self.settings.cluster.styles;
                }

                //  Create Cluster
                self.markerCluster = new MarkerClusterer(self.map, self.markers, clusterSettings);

                google.maps.event.addListener(self.markerCluster, 'clusterclick', function (cluster) {

                    // returns marker in current cluster
                    let markersInCluster = cluster.getMarkers();

                    let nlat;
                    let nlng;
                    let markerIds = [];

                    for (let index = 0; index < markersInCluster.length; index++) {
                        markerIds.push(markersInCluster[index].custom.id);
                    }

                    for (let index = 0; index < markersInCluster.length; index++) {

                        let clat = markersInCluster[index].getPosition().lat();
                        let clng = markersInCluster[index].getPosition().lng();

                        if (index === 0) {

                            nlat = clat;
                            nlng = clng;

                            continue;

                        }

                        if (nlat === clat && nlng === clng && self.map.getZoom() > 16) {

                            self.$mapObject.trigger('map:cluster:zoom:end', markerIds);

                            break;

                        }

                    }

                });

            }

        },
        /**
         * Add Custom Layers to Map
         */
        addLayers: function () {

            if (self.settings.layer.show && self.settings.layer.layers.length > 0) {

                for (let index = 0; index < self.settings.layer.layers.length; index++) {

                    let layer = new google.maps.Polygon(self.settings.layer.layers[index]);
                    layer.setMap(self.map);

                }

            }

        },
        /**
         * Create new Marker
         * @param marker
         */
        addMarker: function (marker) {

            if (isNaN(marker.position.lat) || isNaN(marker.position.lng)) {

                if (App.Globals.debug) {
                    console.log('This location has no LAT|LNG: ' + marker.custom.id);
                }

                return;
            }

            let markerData = {
                map: self.map,
                animation: google.maps.Animation.DROP,
                position: marker.position,
                custom: marker.custom
            };

            //  Add Icon to Marker
            if (marker.icon) {

                markerData.icon = methods.createIcon(marker.icon.default);

                //  Custom Marker field contains the original marker icons
                markerData.custom.icon = {
                    default: marker.icon.default,
                    active: marker.icon.active
                };

            }

            let markerObject = new google.maps.Marker(markerData);

            methods.addInfoWindow(markerObject);

            //  Add Marker to Markers Array
            self.markers.push(markerObject);

            //  Add Marker position to Google Maps Bounds
            self.bounds.extend(marker.position);

        },
        /**
         *
         * @param markerIcon
         * @return {{url, anchor: *, scaledSize: google.maps.Size}}
         */
        createIcon: function (markerIcon) {

            return {
                url: markerIcon.url,
                anchor: new google.maps.Point((markerIcon.size.width / 2), markerIcon.size.height),
                scaledSize: new google.maps.Size(markerIcon.size.width, markerIcon.size.height)
            };

        },
        /**
         * Build-in functionality for generating markers from HTML, All data-attributes are mapped here.
         * @param $markers
         */
        mapHtmlMarkers: function ($markers) {

            let $marker = self.$mapObject.find($markers);

            if ($marker.length > 0) {

                $marker.each(function () {

                    let $this = $(this);
                    let markerData = {
                        custom: {
                            id: $this.data('marker-id')
                        },
                        position: {
                            lat: parseFloat($this.data('marker-lat')),
                            lng: parseFloat($this.data('marker-lng'))
                        }
                    };

                    //  Add Default Icon
                    if ($this.data('marker-icon-default')) {

                        markerData.icon = {
                            default: {
                                url: $this.data('marker-icon-default')
                            }
                        };

                        if ($this.data('marker-icon-default-size')) {

                            let size = $this.data('marker-icon-default-size').split(',');

                            markerData.icon.default.size = {
                                height: parseInt(size[1]),
                                width: parseInt(size[0])
                            };

                        }

                    }

                    //  Add Active Icon
                    if ($this.data('marker-icon-active') && $this.data('marker-icon-active-size')) {

                        let size = $this.data('marker-icon-active-size').split(',');

                        markerData.icon.active = {
                            url: $this.data('marker-icon-active'),
                            size: {
                                height: parseInt(size[1]),
                                width: parseInt(size[0])
                            }
                        };

                    } else {

                        //  In case you do not define an active icon we use the default
                        if ($this.data('marker-icon-default')) {

                            markerData.icon.active = {
                                url: markerData.icon.default.url,
                                size: {
                                    height: markerData.icon.default.size.height,
                                    width: markerData.icon.default.size.width
                                }

                            };

                        }

                    }

                    //  Add InfoWindow
                    if (self.settings.infoWindow.show) {
                        markerData.infoWindow = $this.find('[data-marker-info-window]').html();
                    }

                    //  Add categories
                    if ($this.data('marker-filter-name') && $this.data('marker-filter-value')) {

                        let categories = [];

                        $this.data('marker-filter-name').each(function () {

                            categories.push({
                                name: $(this).data('marker-filter-name'),
                                value: $(this).data('marker-filter-value')
                            });

                        });

                        markerData.custom.categories = categories;

                    }

                    //  Create a Marker
                    methods.addMarker(markerData);
                });

            }

        },
        /**
         * Re-initialize the items on the map
         */
        resetMarkers: function () {

            //  Remove Markers from Map
            for (let index = 0; index < self.markers.length; index++) {
                self.markers[index].setMap(null);
            }

            // Clear clusters
            if (self.settings.cluster.show) {
                self.markerCluster.clearMarkers();
            }

            //  Reset Markers
            self.markers = [];

            //  Reset Bounds
            self.bounds = new google.maps.LatLngBounds();

        },
        /**
         * Resets the Markers/Clusters on the Map and fills them new Markers
         * @param markers
         */
        filterMarkers: function (markers) {

            if (markers.length > 0) {

                //  Re-init all Markers/Clusters
                methods.resetMarkers();

                //  Add new Markers to Map
                for (let index = 0; index < markers.length; index++) {
                    methods.addMarker(markers[index]);
                }

                //  Add Cluster
                methods.addCluster();

                //  Recalculate Map Bounds
                methods.setBounds();

            }

        },
        /**
         * Recalculate Map Bounds
         */
        setBounds: function () {

            self.map.fitBounds(self.bounds);

        },
        /**
         * Close all infoWindows
         */
        closeAllInfoWindows: function () {

            if (self.settings.infoWindow.show) {
                self.infoWindow.close();
            }

        },
        /**
         *
         * @param markerObject
         */
        addInfoWindow: function (markerObject) {

            google.maps.event.addListener(markerObject, 'click', (function () {
                return function () {

                    if (markerObject.custom.icon) {

                        //  Reset Icon on all Markers
                        for (let index = 0; index < self.markers.length; index++) {
                            self.markers[index].setIcon(methods.createIcon(self.markers[index].custom.icon.default));
                        }

                        //  Set Active Icon for current Marker
                        markerObject.setIcon(methods.createIcon(markerObject.custom.icon.active));

                    }

                    //  Center the clicked Marker
                    self.map.panTo(markerObject.position);

                    //  InfoWindow
                    if (self.settings.infoWindow.show) {

                        methods.closeAllInfoWindows();

                        //  This trigger makes it possible to identify and opened infoWindow
                        self.$mapObject.trigger('map:infowindow:open:id', [markerObject.custom.id]);

                    }

                };

            })());

        },
        /**
         * Create and populate the InfoWindow with HTML template
         * @param markerId
         * @param html
         */
        showInfoWindow: function (markerId, html) {

            if (self.markers.length > 0 && markerId && html) {

                for (let index = 0; index < self.markers.length; index++) {

                    let markerObject = self.markers[index];

                    if (markerObject.custom.id == markerId) {

                        self.infoWindow.setContent(html);
                        self.infoWindow.open(self.map, markerObject);

                        //  Remove default styling from Google Maps InfoWindow
                        $('.gm-style-iw').prev('div').remove();

                        setTimeout(function () {
                            $('[data-google-map-holder] [data-close-infowindow]').on('click', function (ev) {
                                ev.preventDefault();

                                self.$mapObject.trigger('map:infowindow:close:id', [markerObject.custom.id]);

                                //  Reset Icon on all Markers
                                for (let index = 0; index < self.markers.length; index++) {
                                    self.markers[index].setIcon(methods.createIcon(self.markers[index].custom.icon.default));
                                }

                                //  Close this InfoWindow
                                methods.closeAllInfoWindows();

                                //  Pan the Map back in it's original position
                                if (self.settings.infoWindow.panOnClose) {
                                    methods.setBounds();
                                }

                            });
                        }, 50);

                    }

                }

            }

        },
        /**
         * Public function to reset the Map. Closes all Info Windows if available and recalculates the Map Bounds
         */
        resetMap: function () {

            methods.closeAllInfoWindows();

            methods.setBounds();

        },
        /**
         * Public function to click on a marker from outside the Lib.
         * @param markerId
         */
        openMarker: function (markerId) {

            if (self.markers.length > 0 && markerId) {

                for (let index = 0; index < self.markers.length; index++) {
                    if (self.markers[index].custom.id == markerId) {

                        new google.maps.event.trigger(self.markers[index], 'click');

                    }
                }

            }

        }
    };

    //  Plugin
    $.fn.customGoogleMap = function (methodOrOptions) {

        if (methods[methodOrOptions]) {

            return methods[methodOrOptions].apply(this, Array.prototype.slice.call(arguments, 1));

        } else if (typeof methodOrOptions === 'object' || !methodOrOptions) {

            //  Default to "init"
            return methods.init.apply(this, arguments);

        } else {

            $.error('Method ' + methodOrOptions + ' does not exist');

        }

    };

})(jQuery);